.about-firt-frame {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 250px;
  margin-bottom: 80px;
  max-width: 1248px;
  margin-bottom: 80px;
  /* height: 280px; */
  /* margin-left: 131px;
  margin-right: 131px; */
}
.about-first-reactangle {
  background: url(../../../../assets/svg/bot-about1.webp) -34px -17.934px /
    111.246% 111.246% no-repeat;
  width: 400px;
  height: 400px;
}
.about-second-reactangle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  flex: 1 0 0;
}
.about-title {
  align-self: stretch;
  color: var(--Total-white, #fff);

  /* H2 (new) */
  font-family: Poppins;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 124%; /* 54.56px */
  letter-spacing: 2.64px;
  text-transform: capitalize;
}
.about-pargraph {
  align-self: stretch;
  color: var(--secondary-light-new, rgba(238, 238, 238, 0.9));

  /* Desktop/Paragraph/Medium */
  font-family: Roboto;
  font-size: 19px;
  font-style: normal;
  font-weight: 300;
  line-height: 148%; /* 28.12px */
  letter-spacing: 0.76px;
}
.about-pargraph span {
  font-weight: 500;
}
@media (max-width: 576px) {
  .about-firt-frame {
    /* flex-direction: column; */
    display: grid;
    gap: 20px;
    margin: 0 auto;
    max-width: 95%;
    padding: 16px;
    padding-bottom: 80px;
  }

  .about-first-reactangle {
    background: url(../../../../assets/svg/bot-about.png) -27px -16.934px /
      110.246% 89.246% no-repeat;
    width: 100%;
    margin-bottom: -80px;
  }

  .about-title {
    align-self: stretch;
    color: var(--Total-white, #fff);
    /* H2 (new) */
    font-family: Poppins;
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: 124%; /* 54.56px */
    letter-spacing: 2.64px;
    text-transform: capitalize;
  }
  .about-pargraph {
    align-self: stretch;
    color: var(--secondary-light-new, rgba(238, 238, 238, 0.9));

    /* Desktop/Paragraph/Medium */
    font-family: Roboto;
    font-size: 19px;
    font-style: normal;
    font-weight: 300;
    line-height: 148%; /* 28.12px */
    letter-spacing: 0.76px;
  }

  .about-first-reactangle img {
    margin-bottom: -95px;
    /* width: 100%;
    height: auto;
    border-radius: 12px; */
  }

  .about-second-reactangle {
    /* align-items: center;
    gap: 16px;
    text-align: center; */
  }

  .about-title {
    /* font-size: 24px;
    line-height: 1.4;
    letter-spacing: 1px; */
  }

  .about-pargraph {
    /* font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.5px;  */
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .about-firt-frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    margin: 60px auto;
    max-width: 90%;
    padding: 50px 20px;
    text-align: center;
  }

  .about-first-reactangle {
    width: 320px;
    height: 320px;
    background-size: cover;
    background-position: center;
  }

  .about-second-reactangle {
    align-items: center;
    text-align: center;
  }

  .about-title {
    font-size: 38px;
    letter-spacing: 2px;
    line-height: 1.2;
  }

  .about-pargraph {
    font-size: 18px;
    line-height: 140%;
    text-align: center;
    /* max-width: 85%; */
  }
}
