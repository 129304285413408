.AiAgent-wrapper {
  background: var(--Bg-Dark, #080d10);
  display: flex;
  width: 100%;
  height: 800px;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  padding-left: 130px;
  padding-right: 130px;
  padding-bottom: 130px;
}

.AiAgnet-title-body {
  /* position: fixed; */
  display: flex;
  width: 621px;
  flex-direction: column;
  align-items: flex-end;
  gap: 32px;
}
.AiAgent-btn-sign {
  display: none;
}
.AiAgent-title {
  color: var(--Total-white, #fff);
  font-family: "Poppins";
  font-size: 44px;
  font-weight: 700;
  line-height: 124%;
  letter-spacing: 2.64px;
  text-transform: capitalize;
  text-align: start;
  gap: 48px;
}

.AiAgent-title span {
  color: var(--Accent-blue, #6c84f8);
}

.AiAgnet-Ai-cards-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  gap: 25px;
}
.AiAgent-Ai-card {
  display: flex;
  /* width: 188px; */
  width: 176px;
  height: 300px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  background: var(--Bg-cards-buttons, #0f161b); /* Default color */
  overflow: hidden;
  cursor: pointer;
  position: relative;
  transition: background 0.3s ease-in-out;
}

/* When video plays, change the background to black */
.AiAgent-Ai-card.video-active {
  background: black;
}

/* Card title styling */
.AiAgent-Ai-card-title {
  position: fixed;
  border-radius: 2px;
  background: var(--Bg-cards-buttons, #0f161b);
  display: flex;
  padding: 32px 8px;
  /* padding: 32px 13px; */
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: relative;
  z-index: 2;
  transition: background 0.3s ease-in-out;
}

/* When video plays, make title background black */
.AiAgent-Ai-card.video-active .AiAgent-Ai-card-title {
  background: black;
}

/* Video container */
.AiAgent-video-container {
  position: relative;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ai-agent-video,
.ai-agent-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

/* Sound Indicator */
.sound-indicator {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #fff;
  background: rgba(0, 0, 0, 0.7);
  padding: 5px 8px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  opacity: 0.9;
  transition: opacity 0.3s;
}

/* Hide Hover Text */
.hover-text {
  /* display: none; */
}

.hover-text {
  position: absolute;
  color: white;
  background: rgba(0, 0, 0, 0.7);
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  opacity: 0.8;
  transition: opacity 0.3s;
}
.AiAgent-Ai-card-title-gen {
  border-radius: 8px;
  background: var(--Bg-cards-buttons, #0f161b);
  display: flex;
  padding: 8px 15px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.Ai-card-title {
  color: var(--Total-white, #fff);
  text-align: center;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 124%;
}
.AiAent-cta {
  width: 824px;
  color: var(--new-secondary-light-new, rgba(238, 238, 238, 0.9));
  font-family: "Roboto";
  font-size: 19px;
  font-weight: 300;
  line-height: 148%;
  letter-spacing: 0.76px;
  text-align: start;
  padding-top: 10px;
}

.ai-agent-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.meet-btn {
  display: flex;
  width: 294px;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-image: url(../../assets/svg/meet-3vo.svg);
  cursor: pointer;
  width: 328px;
  height: 44px;
  flex-shrink: 0;
  transition: transform 0.3s ease-in-out;
}
.meet-btn:hover {
  transform: scale(1.1);
}

.meet-btn-text {
  color: var(--Total-white, #fff);
  font-family: "Inter";
  font-size: 18px;
  font-weight: 700;
  line-height: 136%;
  letter-spacing: 0.36px;
}

@media screen and (max-width: 400px) {
  .AiAgent-wrapper {
    width: 332%;
    margin-top: -340px;
    margin-left: -113px;
  }
  .AiAgent-title {
    color: var(--Total-white, #fff);
    font-family: "Poppins";
    font-size: 32px;
    font-weight: 700;
    line-height: 124%;
    letter-spacing: 2.64px;
    text-transform: capitalize;
    text-align: start;
    padding-right: 228px;
    gap: 48px;
  }
  .AiAgnet-title-body {
    margin-bottom: -7px;
  }
  .voutionaries-text-wrapper-cta {
    color: var(--Total-white, #fff);

    /* Phone/Buttons/CTA */
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 28px */
    letter-spacing: 0.4px;
    padding: 7px;
  }
  .AiAgent-btn-sign {
    display: flex;
    background-image: url(../../assets/svg/join-presale-mobile.svg);
    justify-content: center;
    justify-items: center;
    cursor: pointer;
    width: 328px;
    height: 44px;
    flex-shrink: 0;

    margin-top: 28px;
  }
  .AiAgent-text-wrapper-cta {
    color: var(--Total-white, #fff);

    /* Phone/Buttons/CTA */
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 28px */
    letter-spacing: 0.4px;
    padding: 7px;
  }
  .AiAent-cta {
    color: var(--secondary-light-new, rgba(238, 238, 238, 0.9));
    /* Phone/Paragraph/Medium */
    font-family: Roboto;
    font-size: 17.5px;
    font-style: normal;
    font-weight: 300;
    line-height: 130%; /* 20.8px */
    letter-spacing: 0.64px;
    padding-right: 483px;
  }
  .ai-agent-paragraph {
    margin-bottom: -56px;
    margin-top: 65px;
  }
  .ai-agent-bottom {
    margin-top: 53px;
  }
}
/* Wrapper for AI cards - Enables smooth sliding */
.AiAgnet-Ai-cards-wrapper {
  display: flex;
  width: 100%;
  overflow: hidden;
  transition: transform 0.5s ease-in-out;
}

/* Each card takes full width */
.AiAgent-Ai-card {
  flex: none;
}

/* Navigation Buttons - Show Only on Mobile */
@media screen and (max-width: 400px) {
  .AiAgent-slider-buttons {
    /* position: absolute; */
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    z-index: 10;
  }

  .AiAgent-slider-btn {
    background: #080d10;
    color: white;
    border: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -224px;
  }

  .AiAgent-slider-btn.leftb {
    left: 10px;
    position: static;
    z-index: 1;
  }
  .rightb {
    margin-top: -73px;
    margin-left: 307px;
    position: relative;
  }

  .AiAgent-slider-btn.rightb {
    right: 10px;
    position: sticky;
  }
  .AiAgnet-Ai-cards-wrapper {
    width: 16%;
    overflow: visible;
  }
}

/* Default Hide for Large Screens */
@media screen and (max-width: 501px) {
  /* .AiAgent-slider-buttons {
    display: none;
  } */
}
@media screen and (max-width: 450px) and (min-width: 401px) {
  .AiAgent-slider-buttons {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    z-index: 10;
  }

  .AiAgent-slider-btn {
    background: #080d10;
    color: white;
    border: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -224px;
  }

  .AiAgent-slider-btn.leftb {
    left: 10px;
    position: static;
    z-index: 1;
  }
  .rightb {
    margin-left: 345px;
    margin-top: -73px;
    /* margin-left: 307px; */
    position: relative;
  }

  .AiAgent-slider-btn.rightb {
    right: 10px;
    position: sticky;
  }
  .AiAgnet-Ai-cards-wrapper {
    width: 16%;
    overflow: visible;
  }

  .AiAgent-wrapper {
    width: 332%;
    margin-top: -340px;
    margin-left: -113px;
  }
  .AiAgent-title {
    color: var(--Total-white, #fff);
    font-family: "Poppins";
    font-size: 32px;
    font-weight: 700;
    line-height: 124%;
    letter-spacing: 2.64px;
    text-transform: capitalize;
    text-align: start;
    padding-right: 211px;
    /* padding-right: 228px;    */
    gap: 48px;
  }
  .AiAgnet-title-body {
    margin-bottom: -7px;
  }
  .voutionaries-text-wrapper-cta {
    color: var(--Total-white, #fff);

    /* Phone/Buttons/CTA */
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 28px */
    letter-spacing: 0.4px;
    padding: 7px;
  }
  .AiAgent-btn-sign {
    display: flex;
    background-image: url(../../assets/svg/join-presale-mobile.svg);
    justify-content: center;
    justify-items: center;
    cursor: pointer;
    width: 328px;
    height: 44px;
    flex-shrink: 0;
    margin-top: 31px;
    margin-left: 28px;
    /* margin-top: 20px; */
  }
  .AiAgent-text-wrapper-cta {
    color: var(--Total-white, #fff);

    /* Phone/Buttons/CTA */
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 28px */
    letter-spacing: 0.4px;
    padding: 7px;
  }
  .AiAent-cta {
    color: var(--secondary-light-new, rgba(238, 238, 238, 0.9));
    /* Phone/Paragraph/Medium */
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 130%; /* 20.8px */
    letter-spacing: 0.64px;
    padding-right: 409px;
  }
  .ai-agent-paragraph {
    margin-bottom: -56px;
    margin-top: 65px;
  }
  .ai-agent-bottom {
    margin-top: 53px;
    /* margin-top: -92px; */
  }
}
@media (max-width: 768px) {
  .meet-btn:hover {
    transform: none; /* Prevent scaling effect */
  }
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .AiAgent-wrapper {
    height: auto;
    width: 100%;
    padding: 80px 40px; /* Adjust padding for tablet screens */
    align-items: center;
    gap: 32px;
  }

  .AiAgnet-title-body {
    width: 100%;
    text-align: center;
    align-items: center;
  }

  .AiAgent-title {
    font-size: 36px;
    text-align: center;
    letter-spacing: 2px;
  }

  .AiAgnet-Ai-cards-wrapper {
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }

  .AiAgent-Ai-card {
    width: 200px;
    height: 320px;
  }

  .AiAgent-video-container {
    height: 220px;
  }

  .ai-agent-video,
  .ai-agent-img {
    border-radius: 6px;
  }

  .ai-agent-bottom {
    flex-direction: column;
    text-align: center;
    align-items: center;
    gap: 24px;
  }

  .AiAent-cta {
    font-size: 18px;
    width: 100%;
    text-align: center;
    padding: 0 20px;
  }

  .meet-btn {
    /* width: 250px; */
    height: 44px;
    padding: 10px 20px;
  }
}
