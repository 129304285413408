.TokenThridSection-wrapper {
  display: flex;
  width: 1248px;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  padding-bottom: 80px;
}
.utitlies-title {
  color: var(--Total-white, #fff);
  font-family: "Poppins";
  font-size: 44px;
  font-weight: 700;
  line-height: 124%;
  letter-spacing: 2.64px;
  text-transform: capitalize;
  transform: rotate(0.038deg);
  align-self: stretch;
}
.utitlity-cards {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}
.utiltiy-row-1 {
  display: flex;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}
.utility-fees {
  display: flex;
  width: 188px;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--Button-bg, #263152);
}
.fees-text {
  color: #fff;
  font-family: "Roboto";
  font-size: 22px;
  font-weight: 700;
  line-height: 128%;
  letter-spacing: 0.88px;
}
.utility-liquidity {
  border-radius: 4px;
  background: var(--Button-bg, #263152);
  display: flex;
  width: 506px;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.utility-aiAgent {
  border-radius: 4px;
  background: var(--Button-bg, #263152);
  display: flex;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
}
.utility-profile {
  display: flex;
  width: 612px;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--Button-bg, #263152);
}

.utility-rewards {
  border-radius: 4px;
  background: var(--Button-bg, #263152);
  display: flex;
  width: 188px;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.utility-protocol {
  border-radius: 4px;
  background: var(--Button-bg, #263152);
  display: flex;
  width: 400px;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
@media (max-width: 576px) {
  .TokenThridSection-wrapper {
    width: 100%;
    padding: 40px 20px;
    gap: 32px;
    align-items: center;
    text-align: center;
  }

  .utitlies-title {
    font-size: 32px;
    letter-spacing: 1.5px;
  }

  .utitlity-cards {
    gap: 16px;
    align-items: center;
    width: 100%;
  }

  .utiltiy-row-1 {
    flex-direction: column;
    gap: 12px;
    width: 100%;
  }

  .utility-fees,
  .utility-liquidity,
  .utility-aiAgent,
  .utility-profile,
  .utility-rewards,
  .utility-protocol {
    width: 100%;
    padding: 12px 16px;
    font-size: 18px;
    line-height: 130%;
  }

  .fees-text {
    font-size: 18px;
    letter-spacing: 0.5px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .TokenThridSection-wrapper {
    width: 100%;
    padding: 60px 30px;
    gap: 40px;
    align-items: center;
    text-align: center;
  }

  .utitlies-title {
    font-size: 36px;
    letter-spacing: 2px;
  }

  .utitlity-cards {
    gap: 20px;
    align-items: center;
    width: 100%;
  }

  .utiltiy-row-1 {
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
    width: 100%;
  }

  .utility-fees,
  .utility-liquidity,
  .utility-aiAgent,
  .utility-profile,
  .utility-rewards,
  .utility-protocol {
    width: 52%;
    /* max-width: 400px; */
    padding: 14px 12px;
    font-size: 20px;
    line-height: 130%;
  }

  .fees-text {
    font-size: 20px;
    letter-spacing: 0.7px;
  }
}
