.Feature-section-one {
  display: flex;
  max-width: 1249px;
  flex-direction: column;
  align-items: flex-start;
  gap: 28px;
  padding-top: 40px;
}
.main-feature-title {
  color: var(--Total-white, #fff);
  font-family: "Poppins";
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 124%;
  letter-spacing: 2.64px;
  text-transform: capitalize;
  padding-bottom: 20px;
}
.feature-sections-cards-wrapper {
  /* display : inline-flex; */
  display: flex;
  align-items: flex-start;
  gap: 24px;
  padding-bottom: 100px;
}
.feature-section-card {
  display: flex;
  max-width: 188px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}
.feature-card-tag {
  display: flex;
  padding: 8px 28px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 2px;
  cursor: pointer;
  background: var(--Bg-cards-buttons, #0f161b);
  /* background: var(--Bg-cards-buttons, #0f161b); // not selected*/
  /* background: var(--Accent-Purple, #6C84F8); // is selected */
}
.feature-card-tag.selected {
  background: var(--Accent-Purple, #6c84f8);
}

.feature-tag-name {
  color: #fff;
  text-align: center;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 124%;
}
.feature-header-body-indicator {
  display: flex;
  /* max-width: 1036px; */
  padding: 24px 40px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  border-radius: 12px;
  background: var(--Bg-cards-buttons, #0f161b);
}
.feature-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.feature-body-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 14px;
  align-self: stretch;
}
.feature-indicator {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
}
.feature-header-title {
  display: flex;
  height: 28px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 40px;
}
.feature-header-arrows {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}
.feature-header-title-text {
  max-width: 711px;
  color: var(--Total-white, #fff);
  font-family: "Roboto";
  font-size: 22px;
  font-weight: 700;
  line-height: 128%;
  letter-spacing: 0.88px;
}
.feature-header-arrows-left {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.feature-header-arrows-right {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.feature-arrows-left {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: relative;
}
.feature-arrow-ellipse {
  width: 40px;
  height: 40px;
  fill: var(--Accent-Purple, #6c84f8);
  stroke-width: 1px;
  stroke: var(--Accent-Purple, #6c84f8);
}
.feature-arrow-left {
  stroke-width: 2px;
  stroke: var(--Total-white, #fff);
  /* width: 16px;
  height: 0px; */
  position: absolute;
  /* right: 11px;
  top: 20px; */
}
.feature-arrow-right {
  stroke-width: 2px;
  stroke: var(--Total-white, #fff);
  /* width: 16px;
    height: 0px; */
  position: absolute;
  /* right: 11px;
    top: 20px; */
}
.feature-body {
  display: flex;
  align-items: flex-start;
  gap: 24px;
}
.feature-inline-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex: 1 0 0;
}
.feature-inline-body-text {
  color: var(--Total-white, #fff);
  font-family: "Roboto";
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 130%;
  letter-spacing: 0.72px;
}
.feature-inline-body-text span {
  font-weight: 700;
}
.ai-chat-open {
  max-width: 400px;
  height: 382.807px;
  aspect-ratio: 400/382.81;
  border-radius: 12px;
  background: cover no-repeat;
}
.feature-indcator-icon {
  width: 20px;
  height: 20px;
}

.blockchain-support {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.full-width-image {
  /* width: 100%; */
  width: 960px;
  height: auto;
  display: block;
  object-fit: cover;
  border-radius: 12px; /* Optional for rounded corners */
}

.feature-body.single-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.ai-chat-open.stretched {
  max-width: 100%;
  height: 382.807px;
  aspect-ratio: 400/382.81;
  border-radius: 12px;
  background: cover no-repeat;
}
@media (max-width: 576px) {
  .Feature-section-one {
    padding: 20px;
    gap: 20px;
  }

  .main-feature-title {
    font-size: 28px;
    letter-spacing: 1.5px;
    text-align: center;
  }

  .feature-sections-cards-wrapper {
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding-bottom: 50px;
    width: 100%;
  }

  .feature-card-tag {
    /* flex: 1 1 calc(50% - 8px);  
    text-align: center;
    padding: 6px 16px;
    font-size: 14px; */
    flex: 1 1 calc(33.33% - 10px); /* 3 columns */
    text-align: center;
    padding: 8px 10px;
  }
  .feature-card-tag:nth-child(3n + 1):nth-last-child(1) {
    grid-column: span 3;
  }

  .feature-header-body-indicator {
    padding: 16px;
    width: 100%;
  }
  .ai-chat-open.stretched {
    width: 100%;
    height: 100%;
    aspect-ratio: 642 / 267.81;
    border-radius: 0px;
    background: cover no-repeat;
  }

  .feature-header {
    flex-direction: column;
    text-align: center;
    gap: 10px;
  }

  .feature-header-title-text {
    font-size: 18px;
    max-width: 100%;
  }

  .feature-header-arrows {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    align-self: normal;
    gap: 6px;
  }

  .feature-body-wrapper {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .feature-body {
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }
  .feature-section-card {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns */
    gap: 10px;
    max-width: 100%;
    justify-content: center;
    /* display: flex;
    max-width: 188px;
    flex-direction: row;
     
    gap: 20px;
    align-self: flex-start; */
  }
  .blockchain-logo-e {
    display: none;
  }

  .feature-inline-body {
    text-align: start;
    gap: 12px;
  }

  .feature-inline-body-text {
    font-size: 16px;
    line-height: 1.4;
  }

  .ai-chat-open {
    max-width: 100%;
    height: auto;
  }

  .feature-indicator {
    gap: 4px;
  }

  .feature-indcator-icon {
    width: 12px;
    height: 12px;
  }
}
@media (max-width: 400px) {
  .feature-sections-cards-wrapper {
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding-bottom: 50px;
    width: 100%;
  }
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .Feature-section-one {
    max-width: 90%;
    padding-top: 30px;
    gap: 24px;
    align-items: center;
    margin: 0 auto;
  }

  .main-feature-title {
    font-size: 36px;
    letter-spacing: 2px;
    text-align: center;
  }

  .feature-sections-cards-wrapper {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding-bottom: 80px;
    width: 100%;
  }

  .feature-section-card {
    /* display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px; */
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns */
    gap: 10px;
    max-width: 100%;
    justify-content: center;
  }

  .feature-card-tag {
    flex: 1 1 200px;
    text-align: center;
    padding: 8px 65px;
  }

  .feature-header-body-indicator {
    padding: 20px;
    width: 100%;
    max-width: 800px;
  }

  .feature-header {
    flex-direction: column;
    text-align: center;
    gap: 8px;
  }

  .feature-header-title-text {
    font-size: 20px;
    max-width: 90%;
  }

  .feature-header-arrows {
    display: flex;
    justify-content: space-between;
    gap: 550px;
  }

  .feature-body-wrapper {
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }

  .feature-body {
    flex-direction: column;
    align-items: center;
    gap: 14px;
  }

  .feature-inline-body {
    text-align: center;
    gap: 12px;
  }

  .feature-inline-body-text {
    font-size: 17px;
    line-height: 1.5;
  }

  .ai-chat-open {
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }

  .feature-indicator {
    gap: 6px;
  }

  .feature-indcator-icon {
    width: 14px;
    height: 14px;
  }
}
