.backedBy-section {
  height: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  padding-left: 120px;
  padding-left: 115px;
  padding-right: 95px;
  background: var(--Bg-Dark, #080d10);
}

.backedBy-title {
  color: var(--Total-white, #fff);
  font-family: "Poppins";
  font-size: 44px;
  font-weight: 700;
  line-height: 124%;
  letter-spacing: 2.64px;
  text-transform: capitalize;
}
.backedBy-title span {
  color: var(--Accent-for-text, #6c84f8);
}

.backedBy-container-logos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}
.backedBy-container-logos-row-1 {
  display: flex;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}
.backedBy-container-logos-row-2 {
  display: flex;
  align-items: center;
  gap: 24px;
}

.backedBy-container-logos-1 {
  border-radius: 12px;
  background: var(--Bg-cards-buttons, #0f161b);
  display: flex;
  width: 295px;
  height: 104px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 2px;
}
.backed-clip-logo-1 {
  /* width: 180px;
  height: 52.108px;
  flex-shrink: 0;
  background: 0px 0px / 100% 100% no-repeat; */
  display: flex;
  width: 295px;
  height: 124px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 2px;
}
.backedBy-container-logos-2 {
  border-radius: 12px;
  background: var(--Bg-cards-buttons, #0f161b);
  display: flex;
  width: 294px;
  height: 104px;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.backed-clip-logo-2 {
  width: 185px;
  height: 64px;
  flex-shrink: 0;
}
.backedBy-container-logos-3 {
  border-radius: 12px;
  background: var(--Bg-cards-buttons, #0f161b);
  display: flex;
  width: 294px;
  height: 104px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 14px;
}
.backed-clip-logo-3-a {
  fill: #52d500;
  width: 78.944px;
  height: 35.794px;
  flex-shrink: 0;
}
.backed-clip-logo-3-a {
  width: 81.718px;
  height: 36.412px;
  flex-shrink: 0;
}
.backedBy-container-logos-4 {
  border-radius: 12px;
  background: var(--Bg-cards-buttons, #0f161b);
  display: flex;
  width: 294px;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.backed-clip-logo-4 {
  background: cover no-repeat;
  width: 228px;
  height: 64px;
}
.backedBy-container-logos-row-2 {
  display: flex;
  align-items: center;
  gap: 24px;
}
.backedBy-container-logos-row-2-1 {
  display: flex;
  width: 294px;
  height: 104px;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: var(--Bg-cards-buttons, #0f161b);
}
.backed-clip-logo-2-1 {
  background: contain no-repeat;
  height: 39px;
  flex-shrink: 0;
  align-self: stretch;
}
.backed-clip-logo-2-2 {
  width: 235px;
  height: 65px;
  flex-shrink: 0;
}
.backed-clip-logo-2-3 {
  background: contain no-repeat;
  width: 165px;
  height: 65px;
  flex-shrink: 0;
}
.partner-trailblaze {
  width: 272.342px;
  height: 47.881px;
}
@media screen and (max-width: 400px) {
  .backedBy-section {
    height: auto;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 100px;
  }

  .backedBy-title {
    height: 55px;
    transform: rotate(0.038deg);
    align-self: stretch;
    color: var(--Accent-for-text, #6c84f8);
    text-align: start;
    /* H2 (new) */
    font-family: "Poppins";
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: 124%; /* 54.56px */
    letter-spacing: 2.64px;
    text-transform: capitalize;
    padding-left: 5px;
  }

  .backedBy-container-logos {
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding-left: 10px;
  }

  .backedBy-container-logos-row-1,
  .backedBy-container-logos-row-2 {
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
  }

  .backedBy-container-logos-1,
  .backedBy-container-logos-2,
  .backedBy-container-logos-3,
  .backedBy-container-logos-4,
  .backedBy-container-logos-row-2-1 {
    width: 100%;
    max-width: 320px;
    height: 104px;
    padding: 8px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* gap: 2px; */
    align-self: stretch;
  }
  .backed-clip-logo-3-b,
  .backed-clip-logo-3-a {
    /* width: 100%; */
    /* height: 100px; */
    max-width: 240px;
    object-fit: contain;
  }
  .backed-clip-logo-1,
  .backed-clip-logo-2,
  /* .backed-clip-logo-3-b, */
  .backed-clip-logo-4,
  .backed-clip-logo-2-1,
  .backed-clip-logo-2-2,
  .backed-clip-logo-2-3 {
    width: 100%;
    height: 100px;
    max-width: 240px;
    object-fit: contain;
  }
}
@media screen and (max-width: 450px) and (min-width: 401px) {
  .backedBy-section {
    height: auto;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 80px;
  }

  .backedBy-title {
    font-size: 44px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 1.5px;
    width: auto;
    height: auto;
    padding-left: 0;
  }

  .backedBy-container-logos {
    flex-direction: column;
    align-items: center;
    gap: 16px;
    /* padding-left: 0; */
    /* padding-left: 55px; */
  }

  .backedBy-container-logos-row-1,
  .backedBy-container-logos-row-2 {
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
  }

  .backedBy-container-logos-1,
  .backedBy-container-logos-2,
  .backedBy-container-logos-3,
  .backedBy-container-logos-4,
  .backedBy-container-logos-row-2-1 {
    width: 100%;
    /* max-width: 280px;
    height: 100px; */
    padding: 8px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
    align-self: stretch;
  }

  .backed-clip-logo-1,
  .backed-clip-logo-2,
  /* .backed-clip-logo-3-a,
  .backed-clip-logo-3-b, */
  .backed-clip-logo-4,
  /* .backed-clip-logo-2-1, */
  .backed-clip-logo-2-2,
  .backed-clip-logo-2-3 {
    width: 100%;
    height: 80px;
    max-width: 220px;
    object-fit: contain;
  }

  .backedBy-container-logos-3 {
    gap: 21px;
  }
  .backed-clip-logo-2-1 {
    width: 100%;
    /* height: 80px;
    max-width: 80%; */
    object-fit: contain;
  }
  .backed-clip-logo-1 {
    width: 100%;
    height: 140%;
    max-width: 220px;
    object-fit: contain;
  }
  /* .backed-clip-logo-1, */
  /* .backed-clip-logo-3-a,
  .backed-clip-logo-3-b, */
  .backed-clip-logo-2,
  .backed-clip-logo-4,
  .backed-clip-logo-2-3 {
    width: 100%;
    height: 80px;
    max-width: 220px;
    object-fit: contain;
  }
}

/* ✅ Fix for desktop (≥ 451px) */
@media screen and (min-width: 451px) {
  .backedBy-section {
    padding-left: 115px;
    padding-right: 95px;
  }

  .backedBy-title {
    font-size: 44px;
  }

  .backedBy-container-logos {
    flex-direction: column;
    gap: 24px;
  }

  .backedBy-container-logos-row-1,
  .backedBy-container-logos-row-2 {
    flex-direction: row;
    align-items: center;
    gap: 24px;
  }

  .backedBy-container-logos-1,
  .backedBy-container-logos-2,
  .backedBy-container-logos-3,
  .backedBy-container-logos-4,
  .backedBy-container-logos-row-2-1 {
    width: 295px;
    height: 104px;
    padding: 20px;
  }

  .backed-clip-logo-1,
  .backed-clip-logo-2,
  .backed-clip-logo-3-a,
  .backed-clip-logo-3-b,
  .backed-clip-logo-4,
  .backed-clip-logo-2-1,
  .backed-clip-logo-2-2,
  .backed-clip-logo-2-3 {
    width: auto;
    height: auto;
    max-width: 250px;
  }
  .backed-clip-logo-3-b,
  .backed-clip-logo-3-a {
    width: 50%;
  }
}

/* Breakpoint for screens up to 1024px (Tablets and smaller desktops) */
@media screen and (max-width: 1024px) {
  .backedBy-section {
    padding-left: 60px;
    padding-right: 60px;
  }

  .backedBy-title {
    font-size: 40px;
    text-align: center;
  }

  .backedBy-container-logos {
    gap: 20px;
    display: flex;
    flex-direction: column;
  }

  .backedBy-container-logos-row-1,
  .backedBy-container-logos-row-2,
  .backedBy-container-logos-row-3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
  }

  .backedBy-container-logos-1,
  .backedBy-container-logos-2,
  .backedBy-container-logos-3,
  .backedBy-container-logos-4,
  .backedBy-container-logos-5,
  .backedBy-container-logos-6,
  .backedBy-container-logos-row-2-1 {
    width: 260px;
    height: 90px;
    padding: 15px;
  }
}

/* Breakpoint for screens up to 768px (Mobile devices & small tablets) */
@media screen and (max-width: 768px) {
  .backedBy-section {
    height: auto;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 60px;
  }

  .backedBy-title {
    font-size: 36px;
    text-align: center;
    letter-spacing: 1.2px;
  }

  .backedBy-container-logos {
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }

  .backedBy-container-logos-row-1,
  .backedBy-container-logos-row-2,
  .backedBy-container-logos-row-3 {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 12px;
  }

  .backedBy-container-logos-1,
  .backedBy-container-logos-2,
  .backedBy-container-logos-3,
  .backedBy-container-logos-4,
  .backedBy-container-logos-5,
  .backedBy-container-logos-6,
  .backedBy-container-logos-row-2-1 {
    width: 100%;
    max-width: 280px;
    height: 80px;
    padding: 12px;
  }

  .backed-clip-logo-1,
  .backed-clip-logo-2,
  .backed-clip-logo-3-a,
  .backed-clip-logo-3-b,
  .backed-clip-logo-4,
  .backed-clip-logo-2-1,
  .backed-clip-logo-2-2,
  .backed-clip-logo-2-3 {
    width: 100%;
    /* height: 60px; */
    max-width: 200px;
    object-fit: contain;
  }
}
@media screen and (max-width: 999px) {
  .backedBy-section {
    height: auto;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 60px;
  }

  .backedBy-title {
    font-size: 36px;
    text-align: center;
    letter-spacing: 1.2px;
  }

  .backedBy-container-logos {
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }

  .backedBy-container-logos-row-1,
  .backedBy-container-logos-row-2,
  .backedBy-container-logos-row-3 {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 12px;
  }

  .backedBy-container-logos-1,
  .backedBy-container-logos-2,
  .backedBy-container-logos-3,
  .backedBy-container-logos-4,
  .backedBy-container-logos-5,
  .backedBy-container-logos-6,
  .backedBy-container-logos-row-2-1 {
    width: 100%;
    max-width: 280px;
    height: 80px;
    padding: 12px;
  }

  .backed-clip-logo-1,
  .backed-clip-logo-2,
  .backed-clip-logo-3-a,
  .backed-clip-logo-3-b,
  .backed-clip-logo-4,
  .backed-clip-logo-2-1,
  .backed-clip-logo-2-2,
  .backed-clip-logo-2-3 {
    width: 100%;
    /* height: 60px; */
    max-width: 200px;
    object-fit: contain;
  }
}
