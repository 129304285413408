.trade-token-wrapper {
  /* padding-top: 10px; */
  padding-bottom: 131px;
  background-color: #080d10;
  /* padding-left: 135px;
  padding-right: 96px; */
}
.trade-token-container {
  display: flex;
  /* width: 1248px; */
  padding: 48px 62px 0px 63px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 17px;
  border-radius: 12px;
  background: var(--Bg-Dark, #0f161b);
  z-index: 10001;
}

.mobile-createbtn {
  display: none;
}
.mobile-coins-bg {
  display: none;
}
.tader-group {
  width: 1033px;
  /* height: 827px; */
}
.tader-frame {
  display: flex;
  height: 549px;
  justify-content: center;
  align-items: center;
}
.mobile-createbtn {
  display: none;
}

.tader-frame-image {
  width: 1173px;
  height: 590px;
  background: url(../../assets/AISection/macbook.png) 1px no-repeat;
  z-index: 1000;
}
.token-title-text {
  display: flex;
  width: 824px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
.create-token-btn-text {
  cursor: pointer;
  color: var(--Total-white, #fff);
  font-family: "Inter";
  font-size: 18px;
  font-weight: 700;
  line-height: 136%;
  letter-spacing: 0.36px;
}
.token-title {
  color: #fff;
  text-align: center;
  font-family: "Roboto";
  font-size: 44px;
  font-weight: 700;
  line-height: 124%;
  letter-spacing: 2.64px;
  text-transform: capitalize;
  align-self: stretch;
}

.token-text {
  width: 612px;
  color: var(--new-secondary-light-new, rgba(238, 238, 238, 0.9));
  text-align: center;
  font-family: "Inter";
  font-size: 19px;

  font-weight: 300;
  line-height: 148%; /* 28.12px */
  letter-spacing: 0.76px;
}

.create-token-btn {
  display: flex;
  width: 188px;
  height: 40px;
  justify-content: center;
  align-items: center;
  background-image: url(../../assets/Engagment/earnNowBtn.svg);
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}
.create-token-btn:hover {
  transform: scale(1.1);
}

.Tokennomics-token-btn-text {
  cursor: pointer;
  color: var(--Total-white, #fff);
  font-family: "Inter";
  font-size: 18px;
  font-weight: 700;
  line-height: 136%;
  letter-spacing: 0.36px;
}
.Tokennomics-token-btn {
  display: flex;
  width: 188px;
  height: 40px;
  justify-content: center;
  align-items: center;
  background-image: url(../../assets/Engagment/earnNowBtn.svg);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.Tokennomics-token-btn:hover {
  transform: scale(1.1);
}
/* .Tokennomics-token-btn-text {
  cursor: pointer;
  color: var(--Total-white, #fff);
  font-family: "Inter";
  font-size: 18px;
  font-weight: 700;
  line-height: 136%;
  letter-spacing: 0.36px;
} */
.Tokennomics-2 {
  background-image: url(../../assets/svg/buuton-stroke-get.svg);
  width: 188px;
  height: 40px;
  cursor: pointer;
  z-index: 1;
  background-size: cover;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  position: relative;
  /* display: inline-block; */
}

.Tokennomics-2::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;

  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: #2338c140; /* Change the color as needed */
  transition: left 0.3s ease-in-out;
  z-index: -1;
  border-radius: 10px 7px 10px 10px;
}

.Tokennomics-2:hover::before {
  left: 0;
}
.create-token-btn-m {
  display: none;
}
.Tokennomics-wrapper-cta {
  color: var(--Total-white, #fff);
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 136%;
  letter-spacing: 0.36px;
  padding-top: 7px;
  z-index: 2;
  position: relative;
}
@media screen and (max-width: 400px) {
  .trade-token-container {
    width: 1248px;
  }
  .trade-token-container {
    background: #080d10;
  }
  .tader-frame-image {
    display: none;
  }

  .token-title-text {
    display: flex;
    max-width: 824px;
    flex-direction: column;
    align-items: center;
    margin-left: -843px;
    gap: 16px;
  }
  .token-title {
    color: #fff;
    text-align: center;
    font-family: "Roboto";
    font-size: 37px;
    font-weight: 700;
    line-height: 124%;
    letter-spacing: 2.64px;
    text-transform: capitalize;
    align-self: stretch;
    margin-right: 396px;
    margin-left: 83px;
    margin-top: 135px;
    margin-top: -424px;
  }
  .token-text {
    width: 612px;
    color: var(--new-secondary-light-new, rgba(238, 238, 238, 0.9));
    text-align: center;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 300;
    line-height: 148%;
    letter-spacing: 0.76px;
    padding-right: 275px;
    margin-left: -40px;
  }
  .create-token-btn-text {
    cursor: pointer;
    color: var(--Total-white, #fff);
    font-family: "Inter";
    font-size: 18px;
    font-weight: 700;
    line-height: 136%;
    letter-spacing: 0.36px;
  }
  .create-token-btn-m {
    display: flex;
    width: 188px;
    height: 40px;
    justify-content: center;
    /* align-items: center; */
    background-image: url(../../assets/svg/join-presale-mobile.svg);
  }
  .mobile-coins-bg {
    display: flex;
    width: 374.098px;
    height: 568px;
    flex-shrink: 0;
    margin-left: -306px;
  }
  .mobile-createbtn {
    display: flex;
    margin-left: -313px;
  }
  .create-token-btn {
    display: none;
  }
  .trade-token-wrapper {
    /* padding-top: 10px; */
    padding-bottom: 0px;
    background-color: #080d10;
    padding-left: 135px;
    padding-right: 96px;
  }
}
@media screen and (max-width: 450px) and (min-width: 401px) {
  .trade-token-container {
    width: 1248px;
  }
  .trade-token-container {
    background: #080d10;
  }
  .tader-frame-image {
    display: none;
  }

  .token-title-text {
    display: flex;
    width: 824px;
    flex-direction: column;
    align-items: center;
    margin-left: -528px;
    /* margin-left: -843px;        */
    gap: 16px;
  }
  .token-title {
    color: #fff;
    text-align: center;
    font-family: "Roboto";
    font-size: 37px;
    font-weight: 700;
    line-height: 124%;
    letter-spacing: 2.64px;
    text-transform: capitalize;
    align-self: stretch;
    margin-right: 396px;
    margin-left: 83px;
    margin-top: 135px;
    margin-top: -424px;
  }
  .token-text {
    width: 612px;
    color: var(--new-secondary-light-new, rgba(238, 238, 238, 0.9));
    text-align: center;
    font-family: "Inter";
    font-size: 18px;
    font-weight: 300;
    line-height: 148%;
    letter-spacing: 0.76px;
    padding-right: 275px;
    margin-left: -18px;
  }
  .create-token-btn-text {
    cursor: pointer;
    color: var(--Total-white, #fff);
    font-family: "Inter";
    font-size: 18px;
    font-weight: 700;
    line-height: 136%;
    letter-spacing: 0.36px;
  }
  .create-token-btn-m {
    display: flex;
    width: 188px;
    height: 40px;
    justify-content: center;
    /* align-items: center; */
    background-image: url(../../assets/svg/join-presale-mobile.svg);
  }
  .mobile-coins-bg {
    display: flex;
    width: 374.098px;
    height: 568px;
    flex-shrink: 0;
    margin-left: -306px;
  }
  .mobile-createbtn {
    display: flex;
    margin-left: -295px;
    /* margin-top: -8px; */
    margin-top: -16px;
  }
  .create-token-btn {
    display: none;
  }
  .trade-token-wrapper {
    /* padding-top: 10px; */
    padding-bottom: 0px;
    background-color: #080d10;
    /* padding-left: 135px; */

    padding-right: 96px;
  }
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .trade-token-wrapper {
    width: 100%;
    height: auto;
    padding: 80px 40px;
    display: flex;
    justify-content: center;
  }

  .trade-token-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 32px;
    width: 100%;
  }

  .token-title-text {
    width: 90%;
  }

  .token-title {
    font-size: 36px;
    text-align: center;
    letter-spacing: 1.5px;
  }

  .token-text {
    font-size: 18px;
    text-align: center;
    line-height: 140%;
  }

  .tader-frame {
    height: auto;
  }

  .tader-frame-image {
    background: none;
    content: url("../../assets/AISection/macbook1.png");
    width: 70%;
    max-width: 900px;
    height: auto;
    display: block;
    margin: 0 auto;
  }

  .mobile-createbtn {
    width: 250px;
    height: auto;
    margin-top: 20px;
  }

  .btn-wrapper {
    font-size: 18px;
    text-align: center;
    margin-top: 30px;
  }
}
