.team-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
  padding-bottom: 120px;
}
.team-title {
  color: var(--Total-white, #fff);

  /* H2 (new) */
  font-family: Poppins;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 124%; /* 54.56px */
  letter-spacing: 2.64px;
  text-transform: capitalize;
}
.all-team {
  display: flex;
  width: 1247px;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
}
.team-one {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}
.team-member {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
.team-member-image {
  border-radius: 12px;
  background: contain no-repeat, #0f161b;
  width: 294px;
  height: 294px;
}
.member-name-position {
  /* display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
.member-position {
  color: var(--Accent-Purple, #6c84f8);
  text-align: center;

  /* Desktop/Headlines/Subtitle */
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 128%; /* 28.16px */
  letter-spacing: 0.88px;
}
.member-name {
  align-self: stretch;
  color: var(--Total-white, #fff);
  text-align: start;

  /* Desktop/Headlines/Subtitle */
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 128%; /* 28.16px */
  letter-spacing: 0.88px;
}
.member-links {
  display: flex;
  align-items: center;
  gap: 12px;
}
.team-member-link {
  width: 44px;
  height: 44px;
}
.member-about {
  color: var(--secondary-light-new, rgba(238, 238, 238, 0.9));

  /* Desktop/Paragraph/Medium */
  font-family: Roboto;
  font-size: 19px;
  font-style: normal;
  font-weight: 300;
  line-height: 148%; /* 28.12px */
  letter-spacing: 0.76px;
  align-self: stretch;
  height: 160px;
}
.member-about-1 {
  color: var(--secondary-light-new, rgba(238, 238, 238, 0.9));

  /* Desktop/Paragraph/Medium */
  font-family: Roboto;
  font-size: 19px;
  font-style: normal;
  font-weight: 300;
  line-height: 148%; /* 28.12px */
  letter-spacing: 0.76px;
  align-self: stretch;
  height: 200px;
}
.team-three {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}
.team-member-3 {
  display: flex;
  height: 600px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 12px;
}
.team-image-3 {
  display: flex;
  width: 293px;
  height: 293px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.member-name-position-3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.team-three-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  margin-inline: 307px;
}
.team-three-members {
  border-radius: 12px;
  display: flex;
  height: 600px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.team-three-member-image {
  display: flex;
  width: 293px;
  height: 293px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.team-member-image-3 {
  width: 293px;
  height: 293px;
  flex-shrink: 0;
  border-radius: 12px;
  background: contain no-repeat, #0f161b;
}
.team-member-name-position-3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
@media (max-width: 576px) {
  .team-title {
    font-size: 28px;
    letter-spacing: 1px;
  }

  .all-team {
    gap: 24px;
  }

  .team-one {
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }

  .team-member {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-bottom: 80px;
  }

  .member-name-position {
    display: contents;
  }
  .team-member-image {
    width: 30%;
    height: auto;
  }

  .member-name {
    text-align: center;
    font-size: 26px;
    padding-bottom: 10px;
  }
  .member-position {
    font-size: 22px;
    text-align: center;
    padding-bottom: 10px;
  }

  .member-about {
    font-size: 19px;
    padding-inline: 327px;
    padding-bottom: 20px;
    height: auto;
  }

  .team-three-wrapper {
    flex-direction: column;
    gap: 70px;
    align-items: center;
  }

  .team-three-members {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 30px; */
    padding-bottom: 10px;
  }

  .team-member-image-3 {
    width: 90%;
    height: auto;
  }

  .team-member-name-position-3 {
    display: block;
    flex-direction: column;
    /* align-items: flex-start; */
    gap: 8px;
    /* align-self: stretch; */
  }

  .member-name-position-3 {
    align-items: center;
  }
  .member-about-1 {
    padding-inline: 0px;
    text-align: center;
    height: auto;
  }
}

@media (max-width: 400px) {
  .team-title {
    font-size: 28px;
    letter-spacing: 1px;
  }

  .all-team {
    gap: 24px;
  }

  .team-one {
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }

  .team-member {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-bottom: 80px;
  }

  .member-name-position {
    display: contents;
  }
  .team-member-image {
    width: 30%;
    height: auto;
  }

  .member-name {
    text-align: center;
    font-size: 26px;
    padding-bottom: 10px;
  }
  .member-position {
    font-size: 22px;
    text-align: center;
    padding-bottom: 10px;
  }

  .member-about {
    font-size: 19px;
    padding-inline: 334px;
    height: auto;

    /* padding-inline: 310px; */
    padding-bottom: 20px;
  }

  .team-three-wrapper {
    flex-direction: column;
    /* gap: 32px; */
    align-items: center;
  }

  .team-three-members {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 30px; */
    padding-bottom: 10px;
  }

  .team-member-image-3 {
    width: 90%;
    height: auto;
  }

  .team-member-name-position-3 {
    display: block;
    flex-direction: column;
    /* align-items: flex-start; */
    gap: 8px;
    /* align-self: stretch; */
  }

  .member-name-position-3 {
    align-items: center;
  }
  .member-about-1 {
    padding-inline: 0px;
    text-align: center;
    height: auto;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .team-section {
    gap: 32px;
    padding-bottom: 80px;
  }

  .team-title {
    font-size: 36px;
    letter-spacing: 1.5px;
  }

  .all-team {
    width: 100%;
    gap: 32px;
  }

  .team-one {
    flex-wrap: wrap;
    justify-content: center;
    gap: 32px;
  }

  .team-member {
    width: 45%;
    text-align: center;
  }

  .team-member-image {
    width: 80%;
    height: auto;
  }

  .member-name {
    font-size: 24px;
    text-align: center;
  }

  .member-position {
    font-size: 20px;
    align-self: center;
  }

  .member-about {
    font-size: 18px;
    height: auto;
  }

  .team-three-wrapper {
    flex-wrap: wrap;
    justify-content: center;
    gap: 32px;
    width: 100%;
    margin-inline: 10px;
  }

  .team-three-members {
    width: 42%;
    text-align: center;
  }

  .team-member-image-3 {
    width: 85%;
    height: auto;
  }

  .member-links {
    align-self: center;
  }

  .member-about-1 {
    font-size: 18px;
    text-align: center;
    height: auto;
  }
}
