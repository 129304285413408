.JoinCommunity-section {
  height: 750px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  padding-left: 120px;
  padding-left: 115px;
  padding-right: 95px;
  background: var(--Bg-Dark, #080d10);
  padding-top: 60px;
}
.JoinCommunity-title {
  width: 1250px;
  height: 55px;
  transform: rotate(0.038deg);
  color: var(--Total-white, #fff);
  text-align: center;
  font-family: "Poppins";
  font-size: 44px;
  font-weight: 700;
  line-height: 124%;
  letter-spacing: 2.64px;
  text-transform: capitalize;
}
.JoinCommunity-socials {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 24px;
  align-self: stretch;
}
.JoinCommunity-socials-row {
  display: flex;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}
.JoinCommunity-Advantage-card {
  /* display: flex;
  width: 400px;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  border-radius: 12px;
  background: var(--Bg-cards-buttons, #0f161b); */
  display: flex;
  width: 400px;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  border-radius: 12px;
  background: var(--Bg-cards-buttons, #0f161b);
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
  cursor: pointer;
}
/* background: var(--Accent-Purple, #6c84f8) hover */
.JoinCommunity-Advantage-card:hover {
  background: #6c84f8; /* Blue color */
}

.JoinCommunity-Advantage-card:hover .Advantage-card-header-title,
.JoinCommunity-Advantage-card:hover .Advantage-card-header-number,
.JoinCommunity-Advantage-card:hover .Advantage-card-join {
  color: white; /* Change text color to white */
}
.Advantage-card-header {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}
.Advantage-card-header-title {
  color: #fff;
  text-align: center;
  font-family: "Roboto";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 128%;
  letter-spacing: 0.88px;
  text-align: center;
}
.Advantage-card-header-title span {
  color: var(--tertiary-light, #656869);
}
.Advantage-card-header-number {
  color: #fff;
  text-align: center;
  font-family: "Roboto";
  font-size: 40px;
  font-weight: 400;
  line-height: 105%;
}
.Advantage-card-logo {
  display: flex;
  width: 64px;
  height: 64px;
  justify-content: center;
  align-items: center;
}
.social-logo {
  background: cover no-repeat;
  width: 64px;
  height: 64px;
  flex-shrink: 0;
}
.Advantage-card-link {
  display: flex;
  align-items: center;
  gap: 12px;
}

.Advantage-card-join {
  /* color: var(--Total-white, #fff); hover */
  color: var(--Accent-Purple, #6c84f8);
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 135%;
  letter-spacing: 0.28px;
}
.card-arrow {
  width: 12px;
  height: 12px;
}

.JoinCommunity-Advantage-card:hover .card-arrow {
  filter: brightness(0) invert(1);
  transform: scale(1.1);
  background: var(#fff);
}

@media screen and (max-width: 400px) {
  .JoinCommunity-section {
    height: 400px;

    padding-left: 16px;
    padding-right: 16px;
    padding-top: 20px;
    padding-bottom: 50px;
  }

  .JoinCommunity-title {
    /* font-size: 32px;  
    text-align: left; 
    margin-bottom: 10px; */
    padding-right: 900px;
    font-size: 32px; /* Adjusted title size */
    text-align: start; /* Align title to start */
    margin-bottom: 10px;
    display: flex;
    flex-direction: column; /* Ensures text is on two lines */
    line-height: 1.2; /* Adjust spacing */
  }

  .JoinCommunity-socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 10px; */
    gap: 50px;
  }

  .JoinCommunity-socials-row {
    display: flex;
    justify-content: center;
    gap: 95px;
  }

  /* Icons only, no borders or background */
  .JoinCommunity-Advantage-card {
    background: none; /* Remove background */
    border: none; /* Remove border */
    padding: 0; /* Remove padding */
    width: auto; /* Adjust width */
  }

  .Advantage-card-logo {
    width: 40px; /* Adjust icon size */
    height: 40px;
  }

  .social-logo {
    /* width: 40px;
    height: 40px; */
  }

  /* Hide other elements inside the card */
  .Advantage-card-header,
  .Advantage-card-link {
    display: none;
  }
}
@media screen and (max-width: 450px) and (min-width: 401px) {
  .JoinCommunity-section {
    height: 400px;

    padding-left: 16px;
    padding-right: 16px;
    /* padding-top: 20px; */
    padding-bottom: 50px;
  }

  .JoinCommunity-title {
    /* font-size: 32px;  
    text-align: left; 
    margin-bottom: 10px; */
    padding-right: 900px;
    font-size: 32px; /* Adjusted title size */
    text-align: start; /* Align title to start */
    margin-bottom: 10px;
    display: flex;
    flex-direction: column; /* Ensures text is on two lines */
    line-height: 1.2; /* Adjust spacing */
  }

  .JoinCommunity-socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 10px; */
    gap: 50px;
  }

  .JoinCommunity-socials-row {
    display: flex;
    justify-content: center;
    gap: 95px;
  }

  /* Icons only, no borders or background */
  .JoinCommunity-Advantage-card {
    background: none; /* Remove background */
    border: none; /* Remove border */
    padding: 0; /* Remove padding */
    width: auto; /* Adjust width */
  }

  .Advantage-card-logo {
    width: 40px; /* Adjust icon size */
    height: 40px;
  }

  .social-logo {
    /* width: 40px;
    height: 40px; */
  }

  /* Hide other elements inside the card */
  .Advantage-card-header,
  .Advantage-card-link {
    display: none;
  }
}
/* .JoinCommunity-section {
    height: auto;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
    padding-bottom: 50px;
    align-items: center;
  }

  .JoinCommunity-title {
    font-size: 32px;
    text-align: center;
    margin-bottom: 20px;
    width: 100%;
    line-height: 1.2;
  }

  .JoinCommunity-socials {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  .JoinCommunity-socials-row {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

   
  .JoinCommunity-Advantage-card {
    width: 280px;
    padding: 15px;
    background: var(--Bg-cards-buttons, #0f161b);
    border-radius: 10px;
    transition: background 0.3s ease-in-out;
  }

  .JoinCommunity-Advantage-card:hover {
    background: #6c84f8;
  }

  
  .Advantage-card-header-title {
    font-size: 18px;
    text-align: center;
  }

  
  .Advantage-card-logo {
    width: 50px;
    height: 50px;
  }

  .social-logo {
    width: 100%;
    height: auto;
  }

  
  .Advantage-card-link {
    justify-content: center;
  }

  .Advantage-card-join {
    font-size: 14px;
  }

  .card-arrow {
    width: 12px;
    height: 12px;
  }
} */

/* @media screen and (min-width: 451px) {
  .JoinCommunity-section {
    padding-left: 115px;
    padding-right: 95px;
  }

  .JoinCommunity-socials-row {
    flex-direction: row;
    justify-content: space-between;
  }

  .JoinCommunity-Advantage-card {
    width: 400px;
  }

  .Advantage-card-header-title {
    font-size: 22px;
  }
} */
@media screen and (max-width: 768px) {
  .JoinCommunity-title {
    text-align: start;
  }
}

@media screen and (max-width: 1024px) {
  .JoinCommunity-title {
    text-align: start;
  }
}
