.Mission-firt-frame {
  display: flex;
  max-width: 1249px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 48px;
  padding-bottom: 80px;
}
.mission-rectangle {
  border-radius: 12px;
  background: var(--New-Cards-bg_new, #1c202d);
  display: flex;
  max-width: 1248px;
  padding: 40px 64px;
  justify-content: space-between;
  align-items: center;
}
.mission-title-wrapper {
  display: flex;
  max-width: 336px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex-shrink: 0;
}
.mission-sub {
  color: var(--secondary-light-new, rgba(238, 238, 238, 0.9));

  /* Desktop/Caption/Medium */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 124%; /* 19.84px */
  align-self: stretch;
}

.mission-title {
  align-self: stretch;
  color: var(--Total-white, #fff);

  /* H2 (new) */
  font-family: Poppins;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 124%; /* 54.56px */
  letter-spacing: 2.64px;
  text-transform: capitalize;
}
.mission-content {
  display: flex;
  align-items: center;
  gap: 350px;
}

.mission-points {
  max-width: auto;
  height: auto;
  color: var(--secondary-light-new, rgba(238, 238, 238, 0.9));

  /* Desktop/Paragraph/Medium */
  font-family: Roboto;
  font-size: 19px;
  font-style: normal;
  font-weight: 300;
  line-height: 148%; /* 28.12px */
  letter-spacing: 0.76px;
}
.mission-points {
  list-style-type: none;
  padding-left: 0;
}

.mission-points li {
  display: flex;
  align-items: center;
}

.dot {
  margin-right: 8px;
}
.mission-sub-text {
  color: var(--secondary-light-new, rgba(238, 238, 238, 0.9));
  max-width: 431px;
  flex-shrink: 0;
  /* Desktop/Paragraph/Medium */
  font-family: Roboto;
  font-size: 19px;
  font-style: normal;
  font-weight: 300;
  line-height: 148%; /* 28.12px */
  letter-spacing: 0.76px;
}

@media (max-width: 576px) {
  .Mission-firt-frame {
    padding: 0 16px;
    gap: 24px;
    padding-bottom: 80px;
  }

  .mission-rectangle {
    padding: 34px;
  }

  .mission-title-wrapper {
    max-width: 100%;
  }

  .mission-title {
    font-size: 28px;
    letter-spacing: 0.5px;
    line-height: 120%;
    order: 1;
  }

  .mission-sub {
    font-size: 14px;
    order: 2;
  }

  .mission-content {
    gap: 16px;
    flex-direction: column;
  }

  .mission-sub-text,
  .mission-points {
    font-size: 15px;
    line-height: 135%;
    order: 3;
  }
}

@media (max-width: 400px) {
  .mission-title {
    font-size: 24px;
  }

  .mission-sub {
    font-size: 13px;
  }

  .mission-sub-text,
  .mission-points {
    font-size: 14px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .Mission-firt-frame {
    padding: 0 24px;
    gap: 32px;
    padding-bottom: 80px;
    max-width: 100%;
  }

  .mission-rectangle {
    flex-direction: column;
    padding: 40px;
    text-align: center;
    align-items: center;
    gap: 24px;
    width: 100%;
  }

  .mission-content {
    flex-direction: column;
    gap: 24px;
    text-align: center;
  }

  .mission-title-wrapper {
    max-width: 100%;
    align-items: center;
  }

  .mission-title {
    font-size: 36px;
    letter-spacing: 1px;
    line-height: 120%;
    order: 1;
  }

  .mission-sub {
    font-size: 16px;
    order: 2;
  }

  .mission-sub-text,
  .mission-points {
    font-size: 17px;
    line-height: 140%;
    text-align: center;
    order: 3;
  }

  .mission-points {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }
}
