.Tokenomics-section {
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  padding-left: 115px;
  padding-right: 95px;
  padding-bottom: 70px;
  background: var(--Bg-Dark, #080d10);
  /* padding-top: 100px; */
}
.illustration-map {
  width: 824px;
  height: 596px;
  flex-shrink: 0;
}
.Tokenomics-illustration {
  width: 824px;
  height: 596px;
  flex-shrink: 0;
}
.Tokenomics-3voToken {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
}
.Tokennomics-buttons-m {
  display: none;
}
.Tokennomics-title {
  align-self: stretch;
  color: var(--Total-white, #fff);
  font-family: "Poppins";
  font-size: 44px;
  font-weight: 700;
  line-height: 124%;
  letter-spacing: 2.64px;
  text-transform: capitalize;
  text-align: start;
}
.Tokennomics-cards-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}
.Tokennomics-cards {
  display: flex;
  width: 400px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 24px;
  flex-wrap: wrap;
}

.Tokennomics-buttons {
  display: flex;
  height: 40px;
  align-items: flex-start;
  gap: 24px;
}
.Tokennomics-card-inner {
  display: flex;
  width: 188px;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 12px;
  background: var(--Bg-cards-buttons, #0f161b);
}
.Tokennomics-card-inner-icons {
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  background: var(--Bg-cards-buttons, #0f161b);
}
.Tokennomics-card-title {
  color: var(--Total-white, #fff);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 124%;
}
.Tokennomics-card-number {
  color: var(--Accent-for-text, #6c84f8);
  font-family: "Roboto";
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 105%;
}
.Tokennomics-card-network {
  display: flex;
  align-items: center;
  gap: 8px;
}
.network-coin-icon {
  width: 44px;
  height: 44px;
}
.network-coin-icon-e {
  width: 44px;
  height: 44px;
  border-radius: 46px;
  background: #627eea;
}
.network-coin-icon-b {
  border-radius: 46px;
  background: #f3b82d;
  width: 44px;
  height: 44px;
}
@media screen and (max-width: 768px) {
  .Tokenomics-section {
    flex-direction: column;
    align-items: center;
    padding: 0px 20px;
    height: auto;
    /* margin-top: -130px; */
    /* margin-top: -110px; */
  }

  .Tokennomics-title {
    text-align: center;
    font-size: 36px;
    letter-spacing: 2px;
  }
  .Tokennomics-buttons {
    display: none;
  }

  .Tokennomics-cards-buttons {
    align-items: center;
  }

  .Tokennomics-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    justify-content: center;
    align-items: center;
    width: auto;
  }

  .Tokennomics-card-inner,
  .Tokennomics-card-inner-icons {
    width: 160px;
    padding: 15px;
  }

  .Tokennomics-buttons-m {
    display: grid;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    padding-bottom: 50px;
    /* flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px; */
  }
  .Tokennomics-btn-sign {
    display: flex;
    background-image: url(../../assets/svg/join-presale-mobile.svg);
    justify-content: center;
    justify-items: center;
    cursor: pointer;
    width: 328px;
    height: 44px;
    flex-shrink: 0;
    margin-top: -17px;
  }
  .Tokennomics-btn-sign-2 {
    display: flex;
    background-image: url(../../assets/svg/toknomics.svg);
    justify-content: center;
    justify-items: center;
    cursor: pointer;
    width: 328px;
    height: 44px;
    flex-shrink: 0;
    margin-top: -17px;
  }
  .Tokennomics-text-wrapper-cta {
    text-align: center;
    color: var(--Total-white, #fff);
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 136%;
    letter-spacing: 0.36px;
    padding-top: 7px;
  }
  .Tokennomics-token-btn,
  .Tokennomics-2 {
    text-align: center;
    font-size: 18px;

    max-width: 200px;
  }
  .Tokenomics-illustration {
    width: 147%;
    margin-left: -38px;
    height: auto;
    display: flex;
    justify-content: center;
    margin-top: -40px;
  }
  .Tokenomics-illustration img {
    width: 80%;
    height: auto;
  }
}

/* Breakpoint for screens up to 1024px (Tablets and smaller desktops) */
@media screen and (max-width: 1024px) {
  .Tokenomics-section {
    padding-left: 60px;
    padding-right: 60px;
    flex-direction: column;
    align-items: center;
  }

  .Tokennomics-title {
    font-size: 40px;
    text-align: center;
  }

  .Tokennomics-cards {
    display: grid;
    grid-template-columns: repeat(2, 0fr);
    gap: 20px;
    justify-content: center;
    align-items: center;
    width: auto;
  }

  /* .Tokennomics-card-inner,
  .Tokennomics-card-inner-icons {
    width: 200px;
    padding: 15px;
  } */
}
