.TokenSecondSection-wrapper {
  display: flex;
  padding-bottom: 0.451px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 48px;
  padding-bottom: 80px;
}

.Utility-title-body {
  display: flex;
  width: 824px;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  padding-left: 96px;
}

.Utility-title {
  color: var(--Total-white, #fff);
  font-family: "Poppins";
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 124%;
  letter-spacing: 2.64px;
  text-transform: capitalize;
}
.Utility-sub {
  align-self: stretch;
  color: var(--secondary-light-new, rgba(238, 238, 238, 0.9));
  font-family: "Roboto";
  font-size: 19px;
  font-style: normal;
  font-weight: 300;
  line-height: 148%;
  letter-spacing: 0.76px;
}

.utility-key-metrics {
  display: flex;
  width: 1248px;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  margin-inline: auto;
}
.key-matrics-table {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}
.key-matrics-title {
  color: #fff;
  font-family: "Roboto";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 128%;
  letter-spacing: 0.88px;
}

.matrics-table {
  display: flex;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}

.matrics-left {
  border-radius: 12px;
  background: var(--Bg-cards-buttons, #0f161b);
  display: flex;
  width: 612px;
  padding: 12px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.matrics-right {
  border-radius: 12px;
  background: var(--Bg-cards-buttons, #0f161b);
  display: flex;
  width: 612px;
  padding: 12px 20px;
  flex-direction: column;
  align-items: flex-end;
  gap: 12px;
}
.matrics-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.matrics-line-left {
  color: #fff;
  font-family: "Roboto";
  font-size: 19px;
  font-style: normal;
  font-weight: 300;
  line-height: 148%;
  letter-spacing: 0.76px;
}
.matrics-line-right {
  color: #fff;
  font-family: "Roboto";
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 148%;
  letter-spacing: 0.76px;
}
.matrics-line-left span {
  color: var(--secondary-light-new, rgba(238, 238, 238, 0.9));

  /* Desktop/Caption/Small */
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  letter-spacing: 0.24px;
}
.matrics-table-2 {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}
.matrics-right-2 {
  border-radius: 12px;
  background: var(--Bg-cards-buttons, #0f161b);
  display: flex;
  width: 612px;
  padding: 12px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}
@media (max-width: 576px) {
  .TokenSecondSection-wrapper {
    padding: 40px 20px;
    gap: 32px;
    align-items: center;
    text-align: center;
  }

  .Utility-title-body {
    width: 100%;
    padding-left: 0;
    align-items: center;
    gap: 24px;
  }

  .Utility-title {
    font-size: 32px;
    letter-spacing: 1.5px;
  }

  .Utility-sub {
    font-size: 16px;
    line-height: 130%;
    text-align: start;
  }

  .utility-key-metrics {
    width: 100%;
    gap: 32px;
  }

  .key-matrics-table {
    gap: 16px;
  }

  .matrics-table,
  .matrics-table-2 {
    flex-direction: column;
    gap: 16px;
  }

  .matrics-left,
  .matrics-right,
  .matrics-right-2 {
    width: 100%;
    padding: 16px;
  }

  .matrics-line {
    flex-direction: row;
    justify-content: space-between;
  }

  .matrics-line-left,
  .matrics-line-right {
    font-size: 16px;
    line-height: 130%;
    text-align: start;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .TokenSecondSection-wrapper {
    padding: 0px 40px;
    gap: 40px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 60px;
  }

  .key-matrics-title {
    align-self: center;
  }

  .Utility-title-body {
    width: 100%;
    max-width: 90%;
    padding-left: 0;
    align-items: center;
    gap: 32px;
    text-align: center;
  }

  .Utility-title {
    font-size: 38px;
    /* letter-spacing: 2px; */
  }

  .Utility-sub {
    align-self: center;
    font-size: 18px;
    line-height: 140%;
    max-width: 85%;
  }

  .utility-key-metrics {
    width: 100%;
    max-width: 95%;
    gap: 36px;
  }

  .key-matrics-table {
    width: 100%;
    max-width: 850px;
    gap: 20px;
  }

  .matrics-table,
  .matrics-table-2 {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }

  .matrics-left,
  .matrics-right,
  .matrics-right-2 {
    width: 100%;
    max-width: 420px;
    padding: 16px 20px;
    border-radius: 10px;
  }

  .matrics-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .matrics-line-left,
  .matrics-line-right {
    font-size: 18px;
    line-height: 135%;
  }

  .matrics-line-left span {
    font-size: 12px;
    font-weight: 400;
  }
}
