/* General Styling */
.rapper-style {
  background: var(--Bg-Dark, #080d10);
}

.embed {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 20px;
  background: var(--Bg-Dark, #080d10);
}

.embed-container {
  background: var(--Bg-Dark, #0f161b);
  max-width: 1240px;
  width: 100%;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Title Styling */
.embed-title {
  color: var(--Total-white, #fff);
  font-family: "Poppins";
  font-size: 44px;
  font-weight: 700;
  line-height: 124%;
  letter-spacing: 2.64px;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 10px;
}

.embed-date {
  text-align: center;
  font-size: 0.9rem;
  color: #ffffff;
}

/* Terms of Use Content */
.embed-privacy {
  font-size: 1rem;
  color: #ffffff;
  line-height: 1.6;
}

.embed-privacy p {
  margin-bottom: 19px;
  color: var(--Total-white, #fff);
  text-align: start;
  font-family: Inter;
  font-style: normal;
  font-weight: 200;
  line-height: 128%;
  letter-spacing: 0.88px;
}

.embed-privacy-inner-titles {
  color: var(--Total-white, #fff);
  font-family: "Poppins";
  font-size: 29px;
  font-weight: 700;
  line-height: 124%;
  letter-spacing: 2.64px;
  text-transform: capitalize;
  text-align: start;
  margin-top: 30px;
  margin-bottom: 15px;
}

/* Responsive Styles */
@media screen and (max-width: 1024px) {
  .embed-container {
    padding: 30px;
  }

  .embed-title {
    font-size: 1.8rem;
  }

  .embed-privacy-inner-titles {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 768px) {
  .embed {
    padding: 40px 15px;
  }

  .embed-container {
    padding: 20px;
  }

  .embed-title {
    font-size: 1.6rem;
  }

  .embed-privacy-inner-titles {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 480px) {
  .embed {
    padding: 20px 10px;
  }

  .embed-container {
    padding: 15px;
  }

  .embed-title {
    font-size: 1.4rem;
  }

  .embed-privacy-inner-titles {
    font-size: 1.1rem;
  }
}
