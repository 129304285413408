.TokenFirstSection-wrapper {
  display: flex;
  padding: 191.5px 363px 191.5px 364px;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
  padding-top: 200px;
  position: relative;
}
.TokenFirstSection-background {
  position: absolute;
  top: 30px;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../../../../assets/svg/3vo-token.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 0;
}

.token-coins {
  width: 1286.235px;
  height: 655.014px;
  flex-shrink: 0;
  background: no-repeat;
}
.TokenFirstSection-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 44px;
  align-self: stretch;
  z-index: 1;
}

.TokenFirstSection-title-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  align-self: stretch;
}
.token-title-new {
  color: var(--Total-white, #fff);
  text-align: center;
  font-feature-settings: "ss01" on;
  font-family: "Poppins";
  font-size: 78px;
  font-style: normal;
  font-weight: 700;
  line-height: 122%;
  letter-spacing: 4.68px;
  text-transform: capitalize;
}
.token-sub {
  color: var(--secondary-light-new, rgba(238, 238, 238, 0.9));
  font-family: "Roboto";
  font-size: 19px;
  font-style: normal;
  font-weight: 300;
  line-height: 148%;
  letter-spacing: 0.76px;
}
.token-btns {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 28px;
  align-self: stretch;
}

.token-btn-one {
  background-image: url(../../../../assets/svg/join-pre-sale.svg);
  width: 188px;
  height: 40px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.token-btn-one:hover {
  transform: scale(1.1);
}
.token-btn-two {
  background-image: url(../../../../assets/svg/buuton-stroke-get.svg);
  width: 188px;
  height: 40px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
}

.token-btn-two::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;

  /* overflow: hidden; */
  width: 100%;
  height: 100%;
  background-color: #2338c140; /* Change the color as needed */
  transition: left 0.3s ease-in-out;
  opacity: 0;
  border-radius: 10px 7px 10px 10px;
  pointer-events: none;
}

.token-btn-two:hover::before {
  left: 0;
  opacity: 1;
}

.token-btn-wrapper {
  display: flex;
  max-width: 188px;
  /* padding: 8px 20px; */
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.text-wrapper-cta-token {
  color: var(--Total-white, #fff);
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 136%;
  letter-spacing: 0.36px;
  padding-top: 7px;
}
@media (max-width: 576px) {
  .TokenFirstSection-wrapper {
    padding: 274px 20px;
    margin-bottom: 40px;
    text-align: center;
    margin-top: -59px;
  }

  .TokenFirstSection-container {
    z-index: 1;
    gap: 24px;
  }

  .TokenFirstSection-title-body {
    gap: 16px;
  }

  .token-title-new {
    font-size: 42px;
    letter-spacing: 2px;
  }

  .token-sub {
    font-size: 16px;
    line-height: 130%;
  }

  .token-btns {
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding-top: 15px;
    z-index: 2;
    /* gap: 16px; */
  }
  .token-btn-one {
    background-image: url(../../../../assets/svg/join-presale-mobile.svg);
    cursor: pointer;
    width: 328px;
    height: 44px;
  }

  .token-btn-two {
    background-image: url(../../../../assets/svg/button-bg-Light-long.svg);
    width: 328px;
    height: 44px;
    cursor: pointer;
  }
  .token-btn-one,
  .token-btn-two {
    /* width: 100%;
    max-width: 200px;
    height: 50px; */
    /* background-size: contain; */
    background-position: center;
  }

  .token-btn-wrapper {
    max-width: 100%;
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 768px) {
  .TokenFirstSection-background {
    /* background-size: 90%;
    background-position: center 90%;
    top: -30px; */
    background-size: 100%;
    /* background-image: url(../../../../assets/svg/Tokens_mobile.png); */
    /* z-index: 0; */
    top: 70px;
  }
}

@media (max-width: 480px) {
  .TokenFirstSection-background {
    background-size: 100%;
    top: 70px;
    background-image: url(../../../../assets/svg/Tokens_mobile.png);
    /* background-size: 100%;
    background-position: center 95%; */
  }
}
@media (max-width: 400px) {
  .TokenFirstSection-wrapper {
    padding: 274px 20px;
    margin-bottom: 40px;
    text-align: center;
    margin-top: -40px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .TokenFirstSection-wrapper {
    padding: 150px 80px;
    margin-bottom: 60px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .TokenFirstSection-background {
    position: absolute;
    top: 51px;
    left: 0;
    width: 100%;
    height: 85%;
    /* background-size: cover; */
    background-position: center;
  }

  .token-coins {
    width: 100%;
    max-width: 1100px; /* Scales the token image properly */
    height: auto;
  }

  .TokenFirstSection-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 36px;
    width: 100%;
    z-index: 1;
  }

  .TokenFirstSection-title-body {
    gap: 16px;
    text-align: center;
    width: 100%;
  }

  .token-title-new {
    font-size: 60px;
    letter-spacing: 3px;
    text-align: center;
  }

  .token-sub {
    font-size: 18px;
    line-height: 140%;
    max-width: 85%;
  }

  .token-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .token-btn-one,
  .token-btn-two {
    width: 170px;
    height: 40px;
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transition: transform 0.3s ease-in-out;
  }

  .token-btn-one:hover,
  .token-btn-two:hover {
    transform: scale(1.08);
  }

  .token-btn-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
  }

  .text-wrapper-cta-token {
    font-size: 17px;
    letter-spacing: 0.32px;
    padding-top: 5px;
  }
}
