.second-section {
  width: 100vw;
  height: 500px;
  background: var(--Bg-Dark, #080d10);
}

.left-part {
  display: flex;
  justify-content: flex-end;
  width: 867px;
  padding: 120px 0px;
  align-items: center;
  gap: 24px;
  /* margin-left: 95px; */
  margin-left: 578px;
}
.left-wrapper {
  display: flex;
  width: 670px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  flex-shrink: 0;
  margin-left: 40px;
}

.wrapper-title {
  display: flex;
  width: 612px;
  height: 40px;
  justify-content: center;
  align-items: center;
}

/* Title Animation */
.titles {
  color: var(--Total-white, #fff);
  font-feature-settings: "ss01" on;
  font-family: "Poppins";
  font-size: 44px;
  font-weight: 700;
  line-height: 124%;
  letter-spacing: 2.64px;
  width: 612px;
  text-align: start;
  flex-shrink: 0;
  text-transform: capitalize;
  align-self: stretch;
  transform: translateY(-100%);
  opacity: 0;
  transition: transform 0.7s ease-out, opacity 0.7s ease-out;
}

.titles.visible {
  transform: translateY(0);
  opacity: 1;
}

.mobile-btn-sign-l {
  display: none;
}

.wrapper-paragraph {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* gap: 28px; */
  align-self: stretch;
}

.profit-paragraph {
  display: flex;
  width: 612px;
  height: 140px;
  justify-content: center;
  align-items: center;
  padding-right: 27px;
}

.wrapper-title span {
  color: var(--Accent-blue);
}

.right-text {
  width: 612px;
  align-self: stretch;
  color: var(--new-secondary-light-new, rgba(238, 238, 238, 0.9));

  font-family: "Roboto";
  font-size: 19px;
  font-weight: 300;
  line-height: 148%;
  letter-spacing: 0.76px;
  text-align: start;
  /* padding-right: 10px; */
  padding-bottom: 28px;
  opacity: 0;
  transform: translateX(100%); /* Start from right */
  transition: transform 0.7s ease-out, opacity 0.7s ease-out;
}

.right-text.visible {
  opacity: 1;
  transform: translateX(0); /* Move to original position */
}

.explor-btn-wapper {
  display: flex;
  width: 188px;
  height: 40px;
  justify-content: center;
  align-items: center;
}
.explor-btn-wapper img {
  transition: transform 0.3s ease-in-out;
}

.explor-btn-wapper img:hover {
  transform: scale(1.1);
}
.image-text {
  display: none;
}

.image-mobile {
  display: none;
}
.btn-m {
  display: none;
}

.btn-wrapper {
  transform: translateY(+100%); /* Initially off-screen to the top */
  opacity: 0; /* Hidden initially */
  transition: transform 0.7s ease-out, opacity 0.7s ease-out; /* Smooth animation */
}

.btn-wrapper.visible {
  transform: translateY(0); /* Move to the final position */
  opacity: 1; /* Make visible */
}

.right {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  /* margin-top: -440px */
  margin-top: -456px;
  margin-left: 135px;
  /* margin-left: 900px; */
  gap: 24px;
}

.main-image {
  display: none;
  padding-top: 50px;
  width: 450px;
  height: 360px;
  object-fit: contain;
  transition: opacity 0.5s ease;
}

.main-image.active {
  display: block;
  opacity: 1;
}

.tags {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 70px;
}

.tag {
  opacity: 0.3;
  transition: opacity 0.5s ease, border-color 0.5s ease;
  border: 2px solid transparent;

  /* width: 50px;
  height: 50px; */
}

.tag.active {
  opacity: 1;
  border-color: var(--Accent-blue);
}
/*###################################*/
/*###################################*/
/*###################################*/

@media screen and (max-width: 400px) {
  .explor-btn-wapper {
    margin-top: -120px;
    margin-left: -13px;
  }
  .profit-paragraph {
    margin-top: 96px;
    margin-left: -25px;
  }
  .right {
    display: none;
  }
  .second-section {
    margin-bottom: -2px;
    margin-top: -2px;
  }
  /* .right {
    display: none;
  }

  .left-wrapper {
    margin-left: 33px;
  }
  .titles {
    margin-right: 514px;
    font-size: 26px;
    text-align: start;
    font-weight: 400;
    line-height: 122%;
    letter-spacing: 1.56px;
  }
  .left-part {
    display: flex;
    width: 867px;
    padding: 45px 0px;
    align-items: center;
    gap: 8px;
  }

  .right-text {
    font-family: "Inter";
    font-size: 19px;
    font-style: normal;
    font-weight: 300;
    line-height: 148%;
    letter-spacing: 0.76px;
    margin-inline: 0px;
    margin-right: 544px;
    text-align: start;

    transform: translateX(-100%);
    opacity: 0;
    transition: transform 0.7s ease-out, opacity 0.7s ease-out;
  }
  .right-text.visible {
    transform: translateX(0);
    opacity: 1;
  } */
  .image-text {
    color: var(--Secondary-Light, #949596);
    text-align: center;
    display: flex;
    justify-content: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    letter-spacing: 0.28px;
  }
  .image-mobile {
    display: flex;
    padding-left: 28px;
    margin-top: 387px;
    width: 87%;
  }
  .between-wrapper {
    padding-bottom: 22px;
    background: var(--Bg-Dark, #080d10);
    padding-top: 33px;
  }
  /* padding-top: 10px; */

  /* .left-part {
    display: flex;
    width: 867px;
    padding: 120px 0px;
    align-items: center;
    gap: 24px;
    margin-left: -16px;
  } */
  /* .btn-wrapper {
    margin-bottom: 15px;
  } */
  .left-part {
    margin-left: -110px;
    padding-top: 259px;
  }
  .right-text.visible {
    opacity: 1;
    transform: translateX(0);
  }
  .btn-1 {
    display: none;
  }
  .btn-m {
    display: block;
    cursor: pointer;
    margin-left: 22px;
    margin-top: 135px;
  }

  .titles {
    margin-left: -145px;
    /* padding-right: 325px;
    margin-top: -122px;
    margin-left: -93px; */
    /* margin-left: -155px; */
    padding-right: 273px;
    margin-top: -60px;
    /* margin-left: -138px; */
  }

  .right-text {
    padding-right: 290px;
    /* padding-right: 361px; */
    margin-top: -95px;
    opacity: 1;
    transition: transform 0.7s ease-out, opacity 0.7s ease-out;

    transform: translateX(0);
  }
  .right-text.visible {
    /* transform: translateX(-42px); */
    padding-right: 269px;
    /* margin-left: -74px; */
    opacity: 1;
    margin-left: -65px;
  }
  .ai-avatar {
  }
  .mobile-btn-sign {
    display: flex;
    background-image: url(../../assets/svg/join-presale-mobile.svg);
    justify-content: center;
    justify-items: center;
    cursor: pointer;
    width: 328px;
    height: 44px;
    flex-shrink: 0;
    margin-top: -17px;
  }
  .signup-text-wrapper-cta {
    text-align: center;
    color: var(--Total-white, #fff);
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 136%;
    letter-spacing: 0.36px;
    padding-top: 7px;
  }
  .mobile-btn-sign-l {
    display: flex;
    background-image: url(../../assets/svg/join-presale-mobile.svg);
    justify-content: center;
    justify-items: center;
    cursor: pointer;
    width: 328px;
    height: 44px;
    flex-shrink: 0;

    margin-top: 100px;
    margin-left: 19px;
  }
  .signup-text-wrapper-cta-l {
    text-align: center;
    color: var(--Total-white, #fff);
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 136%;
    letter-spacing: 0.36px;
    padding-top: 7px;
  }
}
@media screen and (max-width: 450px) and (min-width: 401px) {
  .explor-btn-wapper {
    margin-top: -120px;
    margin-left: -13px;
  }
  .profit-paragraph {
    /* margin-top: 96px; */
    margin-left: -25px;
    margin-top: 127px;
  }

  .right {
    display: none;
  }
  .second-section {
    margin-bottom: -2px;
    margin-top: -2px;
  }
  /* .right {
    display: none;
  }

  .left-wrapper {
    margin-left: 33px;
  }
  .titles {
    margin-right: 514px;
    font-size: 26px;
    text-align: start;
    font-weight: 400;
    line-height: 122%;
    letter-spacing: 1.56px;
  }
  .left-part {
    display: flex;
    width: 867px;
    padding: 45px 0px;
    align-items: center;
    gap: 8px;
  }

  .right-text {
    font-family: "Inter";
    font-size: 19px;
    font-style: normal;
    font-weight: 300;
    line-height: 148%;
    letter-spacing: 0.76px;
    margin-inline: 0px;
    margin-right: 544px;
    text-align: start;

    transform: translateX(-100%);
    opacity: 0;
    transition: transform 0.7s ease-out, opacity 0.7s ease-out;
  }
  .right-text.visible {
    transform: translateX(0);
    opacity: 1;
  } */
  .image-text {
    color: var(--Secondary-Light, #949596);
    text-align: center;
    display: flex;
    justify-content: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    letter-spacing: 0.28px;
  }
  .image-mobile {
    /* display: flex;
    padding-left: 12px; */
    display: flex;
    padding-left: 39px;
    width: 386px;
    margin-top: 363px;
  }
  .between-wrapper {
    padding-bottom: 22px;
    background: var(--Bg-Dark, #080d10);
    padding-top: 33px;
  }
  /* padding-top: 10px; */

  /* .left-part {
    display: flex;
    width: 867px;
    padding: 120px 0px;
    align-items: center;
    gap: 24px;
    margin-left: -16px;
  } */
  /* .btn-wrapper {
    margin-bottom: 15px;
  } */
  .left-part {
    margin-left: -110px;
    padding-top: 259px;
  }
  .right-text.visible {
    opacity: 1;
    transform: translateX(0);
  }
  .btn-1 {
    display: none;
  }
  .btn-m {
    display: block;
    cursor: pointer;
    margin-left: 22px;
    margin-top: 135px;
  }

  .titles {
    margin-left: -145px;
    /* padding-right: 325px;
    margin-top: -122px;
    margin-left: -93px; */
    /* margin-left: -155px; */
    padding-right: 273px;
    margin-top: -70px;
    /* margin-left: -138px; */
  }

  .right-text {
    padding-right: 290px;
    /* padding-right: 361px; */
    margin-top: -95px;
    opacity: 1;
    transition: transform 0.7s ease-out, opacity 0.7s ease-out;

    transform: translateX(0);
  }
  .right-text.visible {
    /* transform: translateX(-42px); */
    padding-right: 207px;
    /* padding-right: 269px; */
    /* margin-left: -74px; */
    opacity: 1;
    margin-left: -65px;
  }
  .ai-avatar {
  }
  .mobile-btn-sign {
    display: flex;
    background-image: url(../../assets/svg/join-presale-mobile.svg);
    justify-content: center;
    justify-items: center;
    cursor: pointer;
    width: 328px;
    height: 44px;
    flex-shrink: 0;
    margin-top: -17px;
  }
  .signup-text-wrapper-cta {
    text-align: center;
    color: var(--Total-white, #fff);
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 136%;
    letter-spacing: 0.36px;
    padding-top: 7px;
  }
  .mobile-btn-sign-l {
    display: flex;
    background-image: url(../../assets/svg/join-presale-mobile.svg);
    justify-content: center;
    justify-items: center;
    cursor: pointer;
    width: 328px;
    height: 44px;
    flex-shrink: 0;
    margin-left: 84px;
    margin-top: 86px;
    margin-left: 84px;
  }
  .signup-text-wrapper-cta-l {
    text-align: center;
    color: var(--Total-white, #fff);
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 136%;
    letter-spacing: 0.36px;
    padding-top: 7px;
  }
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .second-section {
    height: auto;
    padding: 60px 40px;
    text-align: center;
  }

  .left-part {
    width: 100%;
    margin-left: 0;
    justify-content: center;
    padding: 80px 0;
  }

  .left-wrapper {
    width: 90%;
    align-items: center;
    text-align: center;
    margin-left: 0;
  }

  .titles {
    font-size: 38px;
    width: 100%;
    text-align: center;
    letter-spacing: 1.8px;
  }

  .right-text {
    width: 90%;
    text-align: center;
    padding-bottom: 20px;
    font-size: 18px;
  }

  .profit-paragraph {
    width: 90%;
    text-align: center;
    height: auto;
  }

  .explor-btn-wapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .right {
    flex-direction: column-reverse;
    align-items: center;
    margin: 0 auto;
    gap: 16px;
  }

  .main-image {
    /* display: block;
    width: 80%;
    max-width: 400px;
    height: auto;
    margin: 0 auto; */
  }

  .tags {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
    margin-bottom: 30px;
  }

  .tag {
    /* width: 50px;
    height: 50px; */
  }
}
